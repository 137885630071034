.swiper {
  width: 100%;
  min-height: 260px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
}
